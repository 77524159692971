<template>
  <div class="flex flex-col p-5 bg-black-800 rounded rounded-b-none">
    <div class="border-light rounded">
      <Lane
        v-for="(lane, index) in enabledLanes"
        :key="index"
        :lane="lane"
        :offset="index"
        @play-note="playNote"
        @toggle-note="toggleNote"
      />
    </div>
  </div>
</template>

<script>
import Lane from './Lane.vue';

export default {
  name: 'Grid',

  components: { Lane },

  props: {
    lanes: Array,
  },

  computed: {
    enabledLanes() {
      return this.lanes.filter(lane => lane.enabled);
    },
  },

  methods: {
    toggleNote(note) {
      this.$emit('toggle-note', note);
    },

    playNote(note) {
      this.$emit('play-note', note);
    },
  },
};
</script>
