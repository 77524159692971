<template>
  <button
    class="w-12 h-12 inline-flex items-center justify-center cursor-pointer"
    :class="classes"
    @mousedown="toggle"
    @keydown.space="toggle"
  ></button>
</template>

<script>
export default {
  name: 'Note',
  props: {
    note: Object,
    offset: Number,
  },
  computed: {
    classes() {
      const { on, offset } = this.note;
      return [
        offset % 4 === 0 ? 'border-l-light' : 'borderLight',
        on ? 'bg-blue-300' : 'bg-black-400',
      ];
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle', this.note);
    },
  },
};
</script>
