<template>
  <div class="flex p-3 pr-5 bg-black-800 w-full rounded-br">
    <div
      class="w-44 h-12 inline-flex items-center cursor-default px-1 text-white"
    >
      <span class="text-right w-full">Tempo: {{ value }} bpm</span>
    </div>
    <div class="ml-2 flex-grow inline-flex items-center range-slider-wrap">
      <RangeSlider
        :value="value"
        @input="$emit('input', $event)"
        :min="30"
        :max="240"
      />
    </div>
  </div>
</template>

<script>
import RangeSlider from './RangeSlider.vue';

export default {
  name: 'tempo-control',

  components: {
    RangeSlider,
  },

  props: {
    value: Number,
  },
};
</script>
